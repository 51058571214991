// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: "https://fundsbridge.com.ng/signup",
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  page404: "404",
  errorOccured: "500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    settings: path(ROOTS_DASHBOARD, "settings"),
    kyc: path(ROOTS_DASHBOARD, "settings/kyc"),
    bvn: path(ROOTS_DASHBOARD, "settings/bvn"),
    password: path(ROOTS_DASHBOARD, "settings/password"),
    nok: path(ROOTS_DASHBOARD, "settings/nok"),
    wallet: path(ROOTS_DASHBOARD, "wallet"),
    statement: path(ROOTS_DASHBOARD, "statements"),
    savings: path(ROOTS_DASHBOARD, "savings"),
    addSaving: path(ROOTS_DASHBOARD, "savings/create"),
    investments: path(ROOTS_DASHBOARD, "investments"),
    addInvestment: path(ROOTS_DASHBOARD, "investments/create"),
    addWallet: path(ROOTS_DASHBOARD, "wallet"),
    bills: path(ROOTS_DASHBOARD, "bills"),
    withdrawWallet: path(ROOTS_DASHBOARD, "wallet/withdraw"),
    settingWallet: path(ROOTS_DASHBOARD, "wallet/settings"),
    investorProfile: path(ROOTS_DASHBOARD, "investment-profile"),
    resetPassword: path(ROOTS_DASHBOARD, "password"),
  },
  misc: {
    refer: path(ROOTS_DASHBOARD, "refer"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/404",
];
