import { styled } from "@mui/material/styles";
import { Box, Stack, Container, Typography, Link } from "@mui/material";
// components
import Page from "../../components/Page";
import { LoginForm } from "../../components/authentication/login";
import Logo from "../../components/Logo";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------
export default function Login() {
  return (
    <RootStyle title="Login to Dashboard">
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary", mt: 3, textAlign: "center" }}
          >
            Download the app on:
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 15 }}
          >
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.fundsbridge.app"
              sx={{ width: 1, height: 60, maxWidth: 150 }}
            >
              <Box
                sx={{
                  backgroundImage: "url(/static/google-play-badge.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  width: 1,
                  height: 1,
                }}
              />
            </Link>
            <Link
              target="_blank"
              href="https://apps.apple.com/us/app/fundsbridge/id1661016999"
              sx={{ width: 1, height: 36, maxWidth: 150 }}
            >
              <Box
                sx={{
                  backgroundImage: "url(/static/apple-store-badge.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  width: 1,
                  height: 1,
                }}
              />
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Logo sx={{ mx: "auto", mb: 5 }} />
              <Typography variant="h4" gutterBottom>
                Securely login your FundsBridge
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your email address and password to proceed.
              </Typography>
            </Box>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
