// components
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.general.root,
        icon: <HomeOutlinedIcon />,
      },
      {
        title: "My Account",
        path: PATH_DASHBOARD.general.settings,
        icon: <AccountCircleOutlinedIcon />,
      },
      {
        title: "My Wallet",
        path: PATH_DASHBOARD.general.wallet,
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      {
        title: "Statements & Reports",
        path: PATH_DASHBOARD.general.statement,
        icon: <SummarizeOutlinedIcon />,
      },
      {
        title: "Savings",
        path: PATH_DASHBOARD.general.savings,
        icon: <SavingsOutlinedIcon />,
      },
      {
        title: "Investments",
        path: PATH_DASHBOARD.general.investments,
        icon: <AnalyticsOutlinedIcon />,
      },
      {
        title: "Bill Payments",
        path: PATH_DASHBOARD.general.bills,
        icon: <ReceiptLongOutlinedIcon />,
      },
      {
        title: "Refer a friend",
        path: PATH_DASHBOARD.misc.refer,
        icon: <ShareOutlinedIcon />,
      },
    ],
  },
];

export default sidebarConfig;
