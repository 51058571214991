import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import { setSession, setHeader, setEmail, getEmail } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerifyEmail: false,
  isPopupOpen: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isVerifyEmail, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isInitialized: true,
      isPopupOpen: false,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isVerifyEmail } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
    };
  },
  LOGOUT: (state, action) => {
    const { isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isPopupOpen: isPopUp,
      user: null,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyEmail: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isVerifyEmail = false;
    let user = {};
    try {
      const response = await axios.get(
        `${API_PATH.profile}?email=${getEmail()}`
      );
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        const { profile } = user;
        isAuthenticated = Boolean(profile.uid);
        // eslint-disable-next-line
        if (isAuthenticated && parseInt(profile.is_verified, 10) === 1) {
          isVerifyEmail = true;
        }
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isVerifyEmail,
        user,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user;
      localStorage.setItem(
        "popupName",
        (currentUser && currentUser.profle && currentUser.profile.fname) || ""
      );
      localStorage.setItem(
        "popupEmail",
        (currentUser && currentUser.profle && currentUser.profile.fname) || ""
      );
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }
    logDevice(0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { isPopUp } });
  };
  const switchAccount = async (uid, email) => {
    const response = await axios.post(API_PATH.switchAccount, {
      accountId: uid,
      email,
    });
    const { status, data } = response.data;
    if (status === "success") {
      const { token } = data;
      setSession(token);
      logDevice();
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail: true },
      });
      return true;
    }
    throw new Error("An error has occurred! Please try again later.");
  };
  const deleteAccount = async () => {
    const response = await axios.post(API_PATH.accountDelete, {});
    const { status } = response.data;
    if (status === "success") {
      logout();
      return true;
    }
    throw new Error("An error has occurred! Please try again later.");
  };
  const login = async (email, password) => {
    const response = await axios.post(API_PATH.login, {
      email,
      password,
      method: "email",
    });

    const { status, data } = response.data;
    if (status === "success" || status === "password_update_required") {
      const { token, profile } = data;
      setSession(token);
      setEmail(email);
      const isVerifyEmail = parseInt(profile.is_verified, 10) === 1;
      logDevice();
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
      initialize();
      return status;
    }
    if (status === "not_found") {
      throw new Error(
        "Invalid email address / password. Please confirm and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been suspended! Please contact support for more information."
      );
    }
  };
  const logDevice = async () => {
    axios.post(API_PATH.saveDevice, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      type: 2,
      token: "",
    });
  };

  const resetPassword = async (code, password) => {
    const response = await axios.post(API_PATH.reset, {
      code,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired! Please request a new one and try again."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has been used! Please request a new one and try again."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid! Please request a new one and try again."
        );
      } else if (status === "same_password") {
        throw new Error(
          "The password you provided is the same as your current password! Please enter a new password and try again."
        );
      } else {
        throw new Error("An error occurred! Please try again later.");
      }
    }
  };

  const forgotPassword = async (email) => {
    const response = await axios.post(API_PATH.forgot, {
      email,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Your email address is incorrect! Please confirm and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const verifyAccount = async (code) => {
    const response = await axios.post(API_PATH.verifyCode, { code });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "code_expired") {
        throw new Error(
          "The code you entered has expired! Please request a new one and try again."
        );
      } else if (status === "code_invalid") {
        throw new Error(
          "The code you entered is invalid! Please request a new one and try again."
        );
      } else if (status === "code_used") {
        throw new Error(
          "The code you entered has been used! Please request a new one and try again."
        );
      } else {
        throw new Error(
          "An error occurred while attempting to verify you! Please try again later."
        );
      }
    } else {
      initialize();
    }
  };
  const sendVerifyCode = async () => {
    const response = await axios.get(API_PATH.sendCode);
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "An error occurred while attempting to send you a verification code! Please try again later."
      );
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.setPhoto, {
      photo: values.photo,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    }
    if (status === "error") {
      throw new Error("omething went wrong. Please try again later.");
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const updateSettingsVerification = async (values) => {
    const response = await axios.post(API_PATH.setVerification, {
      ...values,
      idType: values.idType.code,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const updateSettingsPassword = async (values) => {
    const response = await axios.post(API_PATH.setPassword, values);
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const getBanks = async () => {
    const response = await axios.get(API_PATH.getWithdrawalBanks);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAccountName = async (bank, number) => {
    const response = await axios.post(API_PATH.verifyAccount, {
      bank_code: bank,
      account_number: number,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      "We cannot verify your bank account information! Please confirm it is accurate and try again."
    );
  };
  const updateBVN = async (values) => {
    const response = await axios.post(API_PATH.setBVN, {
      ...values,
    });
    const { status, methods, otpSent, reference } = response.data;
    if (status === "success") {
      return { methods, otpSent, reference };
    }
    if (status === "bvn_otp_error") {
      throw new Error(
        "The OTP you entered is invalid! Please confirm and try again."
      );
    }
    if (status === "bvn_name_error") {
      return status;
    }
    throw new Error(
      "There is an issue with your BVN information! Please check your email for more information."
    );
  };
  const updateSettingsNOK = async (values) => {
    const response = await axios.post(API_PATH.setNOK, {
      ...values,
      relationship: values.relationship.code,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating next of kin! Please try again later."
      );
    }
  };
  const updateSettingsCompany = async (values) => {
    const response = await axios.post(API_PATH.setCompany, {
      ...values,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating your company details! Please try again later."
      );
    }
  };
  const checkMonoAccount = async (code, amount, reason) => {
    const response = await axios.post(API_PATH.verifyBank, {
      code,
      reason,
      amount,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data.id || "";
    }
    throw new Error(
      "An error occurred while checking your bank account. Please try again later."
    );
  };
  const addTopup = async (ref, amount, method, type) => {
    const response = await axios.post(API_PATH.topupWallet, {
      amount,
      type,
      method,
      ref,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(
      "An error occurred while verifying your payment. Please try again later or contact support if you were debited."
    );
  };
  const topupSavings = async (ref, amount, method, sid) => {
    const response = await axios.post(API_PATH.topupSavings, {
      amount,
      sid,
      method,
      ref,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(
      "An error occurred while verifying your payment. Please try again later or contact support if you were debited."
    );
  };
  const sendSavingLetter = async (savingId) => {
    const response = await axios.get(
      `${API_PATH.getSavingsLetter}?savingId=${savingId}`
    );
    const { status } = response.data;
    if (status === "success") {
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const sendInvestmentLetter = async (investmentId) => {
    const response = await axios.get(
      `${API_PATH.getInvestmentsLetter}?investmentId=${investmentId}`
    );
    const { status } = response.data;
    if (status === "success") {
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const cancelSaving = async (savingId) => {
    const response = await axios.post(API_PATH.cancelSavings, {
      savingId,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const sendAccountStatement = async () => {
    const response = await axios.get(API_PATH.getStatement);
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const sendPortfolioStatement = async () => {
    const response = await axios.get(API_PATH.getPortfolio);
    const { status } = response.data;
    if (status === "success") {
      return true;
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const cancelInvestment = async (investmentId) => {
    const response = await axios.post(API_PATH.cancelInvestments, {
      investmentId,
      email: getEmail(),
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    if (status === "pending") {
      throw new Error(
        "Your termination request has been sent to other signatories for approval! We'll notify you once the request has been approved."
      );
    }
    throw new Error("Something went wrong. Please try again later.");
  };
  const addWithdraw = async (values) => {
    const response = await axios.post(API_PATH.withdrawWallet, {
      ...values,
      reason: values.reason.code,
      email: getEmail(),
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    } else if (status === "pending") {
      throw new Error(
        "Your withdrawal request has been sent to other signatories for approval! We'll notify you once the request has been approved."
      );
    } else if (status === "invalid_account") {
      throw new Error(
        "No Withdrawal Account detected! Please set a valid account to proceed."
      );
    } else if (status === "amount_too_small") {
      throw new Error(
        `The withdrawal amount is lower than the minimum amount of $minAmount! Please set a valid amount to proceed.`
      );
    } else if (status === "insufficient_balance") {
      throw new Error(
        `You do not have enough balance in your wallet to withdraw.`
      );
    } else if (status === "interval_not_reached") {
      throw new Error(
        `You cannot request withdrawal at the moment! Please try again in 12 hours after your last withdrawal.`
      );
    } else if (status === "verification_error") {
      throw new Error(`Please complete your KYC before requesting withdrawal`);
    } else if (status === "invalid_password") {
      throw new Error(
        `Your password is incorrect! Please confirm and try again.`
      );
    } else {
      throw new Error(
        `Something went wrong. Please try again later or contact support if your wallet is debited.`
      );
    }
  };
  const addWithdrawAccount = async (values) => {
    const response = await axios.post(API_PATH.saveAccount, {
      bank_code: values.bank.code,
      account_number: values.accountNumber,
      bank_name: values.bank.name,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong. Please try again later.`);
  };
  const addSaving = async (values) => {
    const response = await axios.post(API_PATH.saveSavings, {
      name: values.name,
      duration: values.duration,
      amount: values.amount,
      type: values.type,
      frequency: values.frequency,
      method: values.method,
      ref: values.ref,
      paid: values.paid,
      isLoan: values.isLoan,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    } else if (status === "verify_failed") {
      throw new Error(
        `Something went wrong! Please try again later or contact us if you were debited.`
      );
    } else if (status === "insufficient_balance") {
      throw new Error(
        `You do not have sufficient balance in your wallet to pay for this! Please use another payment option to proceed.`
      );
    } else {
      throw new Error(
        `Something went wrong! Please try again later or contact us if you were debited.`
      );
    }
  };
  const saveRiskProfile = async (values) => {
    const response = await axios.post(API_PATH.investorProfile, values);
    const { status } = response.data;
    if (status === "success") {
      initialize();
    } else {
      throw new Error(`Something went wrong! Please try again later.`);
    }
  };
  const payBill = async (values) => {
    const response = await axios.post(API_PATH.payBill, {
      ...values,
      type: values.type.code,
      network:
        (values.network &&
          (values.network.code || values.network.biller_name)) ||
        "",
      plan: (values.plan && values.plan.biller_name) || "",
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
    } else if (status === "insufficient_balance") {
      throw new Error(
        "You do not have enough balance in your wallet to complete this request. Please top up your wallet to proceed."
      );
    } else if (status === "verification_error") {
      throw new Error("Please complete your KYC before requesting purchase.");
    } else if (status === "invalid_password") {
      throw new Error(
        "Your password is incorrect! Please confirm and try again."
      );
    } else {
      throw new Error(
        "Something went wrong. Please try again later or contact us if your wallet is debited."
      );
    }
  };
  const verifyBill = async (type, itemCode, code, customer) => {
    const response = await axios.post(API_PATH.verifyBill, {
      itemCode,
      code,
      customer,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      type === "dstv"
        ? "We cannot validate your Smart Card Number! Please confirm it is accurate and try again later."
        : "We cannot validate your Meter Number! Please confirm it is accurate and try again later."
    );
  };
  const getBeneficiaries = async (type) => {
    const response = await axios.get(
      `${API_PATH.getBillBeneficiaries}?type=${type}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      if (data.length === 0) {
        throw new Error("No beneficiary added for this bill type.");
      }
      return data;
    }
    throw new Error("An error occured! Please try again later.");
  };
  const addInvestments = async (uid) => {
    const response = await axios.post(API_PATH.saveInvestments, {
      uid,
    });
    const { status } = response.data;
    if (status === "success") {
      initialize();
      return;
    }
    throw new Error(`Something went wrong! Please try again later.`);
  };
  const getSaving = async (sid) => {
    const response = await axios.get(`${API_PATH.savingDetails}?saving=${sid}`);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      `An error occurred while fetching your saving details! Please try again later.`
    );
  };
  const getInvestment = async (pid) => {
    const response = await axios.get(
      `${API_PATH.investmentDetails}?investment=${pid}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error(
      `An error occurred while fetching your investment details! Please try again later.`
    );
  };
  const getDefaults = async () => {
    const response = await axios.get(API_PATH.getDefaults);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getSavingsPlans = async () => {
    const response = await axios.get(API_PATH.getSavingsPlans);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getInvestTypes = async (uid) => {
    const response = await axios.get(
      `${API_PATH.getInvestmentsTypes}?uid=${uid}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getInvestCategories = async () => {
    const response = await axios.get(API_PATH.getInvestmentsCategories);
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  const fetchNetwork = async (type, code) => {
    const response = await axios.get(
      `${API_PATH.getBillNetworks}?type=${type}&code=${code}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const fetchBeneficiaries = async (type) => {
    const response = await axios.get(
      `${API_PATH.getBillBeneficiaries}?type=${type}`
    );
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        getInvestCategories,
        saveToken,
        login,
        logout,
        updateSettings,
        sendPasswordVerify,
        getBanks,
        getAccountName,
        forgotPassword,
        resetPassword,
        updateSettingsNOK,
        updateSettingsCompany,
        addSaving,
        saveRiskProfile,
        sendVerifyCode,
        verifyAccount,
        updateBVN,
        initialize,
        getDefaults,
        updateSettingsVerification,
        updateSettingsPassword,
        addTopup,
        checkMonoAccount,
        cancelSaving,
        cancelInvestment,
        addWithdraw,
        getSavingsPlans,
        addWithdrawAccount,
        getInvestTypes,
        addInvestments,
        fetchNetwork,
        fetchBeneficiaries,
        verifyBill,
        payBill,
        getBeneficiaries,
        getSaving,
        getInvestment,
        topupSavings,
        sendInvestmentLetter,
        sendSavingLetter,
        deleteAccount,
        switchAccount,
        sendAccountStatement,
        sendPortfolioStatement,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
