import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
  Button,
} from "@mui/material";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

PasswordPopup.propTypes = {
  isOpen: PropTypes.bool,
};
export default function PasswordPopup({ isOpen }) {
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <DialogTitle sx={{ color: "primary.main" }}>
        Welcome,{" "}
        {user &&
          user.profile &&
          ((user.company && user.company.name) || user.profile.fname)}
        !
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          For security reasons, you are required to reset your default password.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            component={RouterLink}
            to={PATH_DASHBOARD.general.resetPassword}
            fullWidth
            color="primary"
            variant="contained"
          >
            Reset Password
          </Button>
          <Button
            onClick={() => {
              logout();
            }}
            color="error"
          >
            Logout
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
