import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        {
          path: "settings",
          children: [
            { path: "", element: <Settings /> },
            { path: ":tab", element: <Settings /> },
          ],
        },
        {
          path: "savings",
          children: [
            {
              path: "",
              element: <Savings />,
            },
            {
              path: ":sid",
              element: <Saving />,
            },
            {
              path: "create",
              element: <AddSaving />,
            },
          ],
        },
        {
          path: "investments",
          children: [
            {
              path: "",
              element: <Investments />,
            },
            {
              path: ":pid",
              element: <Investment />,
            },
            {
              path: "create",
              element: <AddInvestment />,
            },
          ],
        },
        {
          path: "wallet",
          children: [
            {
              path: "",
              element: <Wallet />,
            },
            {
              path: "withdraw",
              element: <WithdrawWallet />,
            },
            {
              path: "settings",
              element: <WalletSettings />,
            },
          ],
        },
        { path: "refer", element: <Refer /> },
        { path: "bills", element: <Bills /> },
        { path: "statements", element: <Statements /> },
        { path: "investment-profile", element: <RiskProfile /> },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        { path: "password", element: <Password /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        {
          path: "verify",
          element: (
            <AuthGuard>
              <VerifyCode />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Settings = Loadable(lazy(() => import("../pages/dashboard/Settings")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Savings = Loadable(lazy(() => import("../pages/dashboard/Savings")));
const Saving = Loadable(lazy(() => import("../pages/dashboard/Saving")));
const Statements = Loadable(
  lazy(() => import("../pages/dashboard/Statements"))
);
const Investment = Loadable(
  lazy(() => import("../pages/dashboard/Investment"))
);
const Investments = Loadable(
  lazy(() => import("../pages/dashboard/Investments"))
);
const AddInvestment = Loadable(
  lazy(() => import("../pages/dashboard/AddInvestment"))
);
const AddSaving = Loadable(lazy(() => import("../pages/dashboard/AddSaving")));
const WithdrawWallet = Loadable(
  lazy(() => import("../pages/dashboard/WithdrawWallet"))
);
const WalletSettings = Loadable(
  lazy(() => import("../pages/dashboard/WalletSettings"))
);
const Refer = Loadable(lazy(() => import("../pages/dashboard/Refer")));
const Bills = Loadable(lazy(() => import("../pages/dashboard/Bills")));
const RiskProfile = Loadable(
  lazy(() => import("../pages/dashboard/RiskProfile"))
);
const Wallet = Loadable(lazy(() => import("../pages/dashboard/Wallet")));
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));

// auth
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/Verify"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const Password = Loadable(
  lazy(() => import("../pages/authentication/Password"))
);
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
