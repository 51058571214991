import PropTypes from "prop-types";
// material
import { Box, Typography } from "@mui/material";

// ----------------------------------------------------------------------

Label.propTypes = {
  title: PropTypes.string,
};

export function Label({ title }) {
  return (
    <Typography
      variant="overline"
      component="p"
      gutterBottom
      sx={{ color: "text.secondary" }}
    >
      {title}
    </Typography>
  );
}

Block.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  sx: PropTypes.object,
};

export function Block({ title, sx, children, open }) {
  return open ? (
    <Box sx={{ overflow: "unset", position: "unset", width: "100%", my: 1 }}>
      {title && (
        <Typography
          variant="caption"
          sx={{ fontWeight: 500, color: "text.disabled", ml: 2 }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          p: 0,
          mt: 1,
          minHeight: 100,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  ) : (
    <Box />
  );
}
