import axios from "./axios";

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    setHeader();
  } else {
    localStorage.removeItem("accessToken");
  }
};
const setEmail = (email) => {
  if (email) {
    localStorage.setItem("emailAddress", email);
    setHeader();
  } else {
    localStorage.removeItem("emailAddress");
  }
};
const getEmail = () => localStorage.getItem("emailAddress") || "";
const setHeader = () => {
  const accessToken = window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : "";
  axios.defaults.headers.common.Authorization = `Bearer: ${accessToken}`;
};

export { setSession, setHeader, setEmail, getEmail };
