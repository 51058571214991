import { NumericFormat } from "react-number-format";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.fundbridgeinvestment.com/";
const ROOTS_ASSETS = "https://assets.fundbridgeinvestment.com/";

export const API_PATH = {
  root: ROOTS_API,
  dashboard: path(ROOTS_API, "dashboard"),
  shops: path(ROOTS_API, "shops"),
  login: path(ROOTS_API, "auth/login"),
  sendCode: path(ROOTS_API, "auth/send_verify_code"),
  verifyCode: path(ROOTS_API, "auth/verify_code"),
  cancelSavings: path(ROOTS_API, "savings/cancel"),
  cancelInvestments: path(ROOTS_API, "investments/cancel"),
  getStatement: path(ROOTS_API, "wallet/account_statement_download"),
  getPortfolio: path(ROOTS_API, "wallet/portfolio_download"),
  forgot: path(ROOTS_API, "auth/forgot"),
  reset: path(ROOTS_API, "auth/reset"),
  accountDelete: path(ROOTS_API, "user/delete"),
  switchAccount: path(ROOTS_API, "user/switch_account"),
  topupSavings: path(ROOTS_API, "savings/topup"),
  profile: path(ROOTS_API, "user/profile"),
  setNOK: path(ROOTS_API, "user/next-of-kin"),
  setCompany: path(ROOTS_API, "user/company"),
  setVerification: path(ROOTS_API, "user/verification"),
  setBVN: path(ROOTS_API, "user/bvn"),
  setPassword: path(ROOTS_API, "user/password"),
  setPhoto: path(ROOTS_API, "user/upload_photo"),
  getSavings: path(ROOTS_API, "savings"),
  saveSavings: path(ROOTS_API, "savings/save"),
  investorProfile: path(ROOTS_API, "user/investor-profile"),
  saveInvestments: path(ROOTS_API, "investments/save"),
  savingDetails: path(ROOTS_API, "savings/single"),
  getInvestmentsTypes: path(ROOTS_API, "investments/types"),
  getInvestmentsCategories: path(ROOTS_API, "investments/categories"),
  getInvestments: path(ROOTS_API, "investments"),
  getInvestmentsLetter: path(ROOTS_API, "investments/get_letter"),
  getSavingsLetter: path(ROOTS_API, "savings/get_letter"),
  investmentDetails: path(ROOTS_API, "investments/single"),
  settings: path(ROOTS_API, "settings"),
  getReferrers: path(ROOTS_API, "refer"),
  getWallet: path(ROOTS_API, "wallet"),
  getBanks: path(ROOTS_API, "wallet/banks"),
  topupWallet: path(ROOTS_API, "wallet/topup"),
  withdrawWallet: path(ROOTS_API, "wallet/withdraw"),
  getTransactions: path(ROOTS_API, "wallet/transactions"),
  getAllTransactions: path(ROOTS_API, "wallet/allTransactions"),
  saveAccount: path(ROOTS_API, "wallet/account_update"),
  getDefaults: path(ROOTS_API, "misc/defaults"),
  charge: path(ROOTS_API, "misc/charge"),
  verifyAccount: path(ROOTS_API, "misc/verify_account"),
  verifyBank: path(ROOTS_API, "misc/verify_bank"),
  getWithdrawalBanks: path(ROOTS_API, "misc/withdrawal_banks"),
  getSavingsPlans: path(ROOTS_API, "misc/savings_plans"),
  saveDevice: path(ROOTS_API, "misc/save_device"),
  logoutDevice: path(ROOTS_API, "misc/logout_device"),
  getBillNetworks: path(ROOTS_API, "bill/categories"),
  billPayments: path(ROOTS_API, "bill/banks"),
  getBillBeneficiaries: path(ROOTS_API, "bill/beneficiaries"),
  payBill: path(ROOTS_API, "bill/pay"),
  verifyBill: path(ROOTS_API, "bill/validate"),
};
export const GENDERS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
export const AGE_RANGE = ["Below 21", "25 - 40", "41 - 65", "65 - above"];
export const UNDERSTANDING = ["None", "Little", "Adequate"];
export const EXPERIENCE = ["None", "< 3 years", "> 3 years"];
export const PERCENT_INVEST = [
  "Less than 25%",
  "Between 25% - 50%",
  "Above 50%",
];
export const FINANCIAL_GOALS = [
  "Maximum 1 year",
  "Up to 2 years",
  "Up to 7 years",
  "Up to 10 years",
];
export const RETURN_TARGET = [
  "Maximum 1 year",
  "Up to 2 years",
  "Up to 7 years",
  "Up to 10 years",
];
export const RATINGS = [
  {
    title: "Risk Averse",
    description:
      "This client's main objective is capital preservation and he/she prefers not to take any investment risk, therefore your return preference will be deposit rates. The portfolio will comprise largely of deposit investments and investment horizon is very short.",
  },
  {
    title: "Conservative",
    description:
      "This client's major objective is partly capital preservation, while achieving a higher return than deposit interest rate. The client has a slightly longer investment horizon than the risk averse client and is willing to accept some level of risk to achieve overall long term investment objective. The portfolio will comprise of deposit investments, treasury bill and sovereign bonds.",
  },
  {
    title: "Moderate",
    description:
      "This client is willing to take a higher level of risk to achieve capital growth through investment in multiple income streams largely comprising of sovereign & corporate fixed income securities and some equity. The potential return is higher, but the client is also aware that the potential losses do exist and accepts that value of the portfolio may fluctuate in the short run, while long term return objective are more bullish. The investment horizon is much longer but not more than 3 years without market risk during disposal.",
  },
  {
    title: "Moderately Aggressive",
    description:
      "This client major objective is capital growth and he/she aims for higher potential returns. The client is willing to accept higher level of risk and volatility. The client portfolio consist of high yield debt notes, equity linked notes and equity. The client has a long term investment horizon and accepts higher fluctuations in the short term to achieve long term capital growth objective in the long term.",
  },
  {
    title: "Highly aggressive",
    description:
      "The client portfolio objective is significant capital growth over the medium to long term horizon. The portfolio consists of private high yield instruments, equities and private equity. He is aware that the value of the original investment may have substantial fluctuations and pursue longer term investment return objectives.",
  },
];
export const EDUCATIONAL_QUALIFICATION = [
  "SSCE",
  "BSc.",
  "OND",
  "HND",
  "Masters",
  "Others",
];
export const EMPLOYMENT_STATUS = [
  "Self-employed or business owners",
  "Employee",
  "Student",
  "Unemployed",
  "Home maker",
];
export const COMPLIANCE_MESSAGE = {
  bvn: {
    title: "Verify your Account",
    message: "Provide your BVN to verify your account.",
    actionText: "VERIFY",
    page: PATH_DASHBOARD.general.bvn,
  },
  verification: {
    title: "Complete your KYC",
    message:
      "Let us know who you are by providing your photo ID and other information.",
    actionText: "UPDATE KYC",
    page: PATH_DASHBOARD.general.kyc,
  },
  nok: {
    title: "Next of kin details",
    message: "Update your next of kin information.",
    actionText: "UPDATE",
    page: PATH_DASHBOARD.general.nok,
  },
  clientInvestment: {
    title: "Provide your Investment Profile",
    message:
      "Let us know your investment objectives, risk appetite and what to recommend to you.",
    actionText: "START",
    page: PATH_DASHBOARD.general.investorProfile,
  },
};
export const WITHDRAWAL_REASONS = [
  { name: "Food / Groceries", code: "Food / Groceries" },
  { name: "Entertainment", code: "Entertainment" },
  { name: "Bills", code: "Bills" },
  { name: "Debt Payment", code: "Debt Payment" },
];
export const RELATIONSHIPS = [
  { name: "Husband", code: "Husband" },
  { name: "Wife", code: "Wife" },
  { name: "Sister", code: "Sister" },
  { name: "Brother", code: "Brother" },
  { name: "Father", code: "Father" },
  { name: "Mother", code: "Mother" },
  { name: "Nephew", code: "Nephew" },
  { name: "Niece", code: "Niece" },
  { name: "Cousin", code: "Cousin" },
  { name: "Friend", code: "Friend" },
];
export const IDENTIFICATIONS = [
  { name: "National Identity Number", code: "nin" },
  { name: "International Passport", code: "passport" },
  { name: "Voter's Card", code: "voter" },
  { name: "Driver's License", code: "driver" },
];
export const ASSETS_PATH = {
  investments: path(ROOTS_ASSETS, "investment_types/"),
  users: path(ROOTS_ASSETS, "users/"),
  identifications: path(ROOTS_ASSETS, "identifications/"),
  promo: path(ROOTS_ASSETS, "promos/"),
  selfie: path(ROOTS_ASSETS, "selfie/"),
};

export const STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
export const MONTHS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const LGAS = [
  {
    state: "Abia",
    lgas: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South",
    ],
  },
  {
    state: "Adamawa",
    lgas: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    state: "Akwa Ibom",
    lgas: [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo",
    ],
  },
  {
    state: "Anambra",
    lgas: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South",
    ],
  },
  {
    state: "Bauchi",
    lgas: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
  },
  {
    state: "Benue",
    lgas: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    state: "Borno",
    lgas: [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    state: "Bayelsa",
    lgas: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa",
    ],
  },
  {
    state: "Cross River",
    lgas: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
  },
  {
    state: "Delta",
    lgas: [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South",
    ],
  },
  {
    state: "Ebonyi",
    lgas: [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    state: "Edo",
    lgas: [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde",
    ],
  },
  {
    state: "Ekiti",
    lgas: [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye",
    ],
  },
  {
    state: "Enugu",
    lgas: [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    state: "Federal Capital Territory",
    lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
  },
  {
    state: "Gombe",
    lgas: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu/Deba",
    ],
  },
  {
    state: "Imo",
    lgas: [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West",
    ],
  },
  {
    state: "Jigawa",
    lgas: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    state: "Kebbi",
    lgas: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
  },
  {
    state: "Kaduna",
    lgas: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
  },
  {
    state: "Kano",
    lgas: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    state: "Kogi",
    lgas: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West",
    ],
  },
  {
    state: "Katsina",
    lgas: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    state: "Kwara",
    lgas: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    state: "Lagos",
    lgas: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South",
    ],
  },
  {
    state: "Nasarawa",
    lgas: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto",
    ],
  },
  {
    state: "Niger",
    lgas: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    state: "Ogun",
    lgas: [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North",
    ],
  },
  {
    state: "Ondo",
    lgas: [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo",
    ],
  },
  {
    state: "Osun",
    lgas: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    state: "Oyo",
    lgas: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere",
    ],
  },
  {
    state: "Plateau",
    lgas: [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    state: "Rivers",
    lgas: [
      "Abua/Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba/Egbema/Ndoni",
      "Ogu/Bolo",
      "Okrika",
      "Omuma",
      "Opobo/Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
  },
  {
    state: "Sokoto",
    lgas: [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    state: "Taraba",
    lgas: [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    state: "Yobe",
    lgas: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    state: "Zamfara",
    lgas: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
];
export const TRANSACTIONS_STATUS = [
  { label: "Failed", color: "error" },
  {
    label: "Successful",
    color: "success",
  },
];
export const TRANSACTIONS_TYPE = [
  { label: "SAVINGS", color: "success" },
  {
    label: "DEPOSIT",
    color: "success",
  },
  {
    label: "WITHDRAWALS",
    color: "info",
  },
  {
    label: "REFERRALS",
    color: "info",
  },
  {
    label: "INVESTMENTS",
    color: "info",
  },
];
export const REFER_STATUS = [
  { label: "Pending", color: "warning" },
  {
    label: "Paid",
    color: "success",
  },
];
export const SAVING_STATUS = [
  { label: "Cancelled", color: "error" },
  {
    label: "Active",
    color: "success",
  },
  {
    label: "Matured",
    color: "info",
  },
];
export const INVESTMENT_STATUS = [
  { label: "Cancelled", color: "error" },
  {
    label: "Active",
    color: "success",
  },
  {
    label: "Matured",
    color: "info",
  },
  {
    label: "Pending",
    color: "warning",
  },
];
export const SAVING_TYPE = [
  "BUGA Projects Savings",
  "Premium Savings",
  "Padi Savings Cycle",
  "Halal Saving",
  "Fixed Investments",
];
export const COMPLIANCE_STATUS = [
  { label: "Pending", color: "warning" },
  {
    label: "Verified",
    color: "success",
  },
  { label: "Failed", color: "error" },
];
export const SUPPORT_STATUS = [
  {
    label: "Pending",
    color: "warning",
    value: "0",
    icon: "material-symbols:pending-actions",
  },
  {
    label: "Replied",
    color: "success",
    value: "1",
    icon: "icon-park-solid:message-sent",
  },
];
export const SUPPORT_REASONS = [
  "Enquiries",
  "Payment Not Made",
  "System Error",
  "Others",
];
export const LOG_TYPES = [
  { label: "System", value: "0", color: "primary" },
  { label: "Administrator", value: "1", color: "secondary" },
  { label: "Role", value: "2", color: "secondary" },
  { label: "Staff", value: "3", color: "secondary" },
  { label: "Branch", value: "4", color: "info" },
  { label: "Product Category", value: "5", color: "info" },
  { label: "Customer", value: "6", color: "info" },
  { label: "Product", value: "7", color: "info" },
  { label: "Sale/Invoice", value: "8", color: "info" },
  { label: "Returned Product", value: "9", color: "info" },
  { label: "Debtor", value: "10", color: "info" },
  { label: "Damaged Product", value: "11", color: "info" },
  { label: "Purchases", value: "12", color: "info" },
  { label: "Online Sales", value: "13", color: "info" },
  { label: "Gift Card", value: "14", color: "info" },
  { label: "Loyalty Card", value: "15", color: "info" },
  { label: "Wallet", value: "16", color: "warning" },
  { label: "Withdrawal", value: "17", color: "warning" },
  { label: "Expenses", value: "18", color: "warning" },
  { label: "Subscription", value: "19", color: "warning" },
  { label: "Message", value: "20", color: "success" },
  { label: "Import/Export", value: "21", color: "success" },
  { label: "Login", value: "22", color: "success" },
];
export const PAYSTACK_KEY = "pk_live_24bc5927fc110728d9512d0b2520cc40e152d9da";
export const MONO_KEY = "live_pk_uX7NKEEogeKOkaBEZ1He";

export const CheckOnboardingDone = () => {
  const { user } = useAuth();
  const { verification, nok, investmentsProfile } = user;
  let isDone = false;
  if (user.profile && String(user.profile.type) === "3") {
    // joint account, no id needed
    return true;
  }
  if (user && verification) {
    if (verification.bvn) {
      // bvn done
      if (verification.idNum && verification.photo) {
        // verification done
        if (verification.state && verification.address && verification.dob) {
          // addresses done
          if (nok) {
            if (nok.name && nok.relationship) {
              if (investmentsProfile && investmentsProfile.rating) {
                isDone = true;
              }
            }
          }
        }
      }
    }
  }
  return isDone;
};
export const CopyToBoard = (link, enqueueSnackbar, message) => {
  navigator.clipboard.writeText(link);
  enqueueSnackbar(message || "Link copied to clipboard.", {
    variant: "success",
  });
};
export function NumberFormatCustom(props) {
  // eslint-disable-next-line react/prop-types
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      prefix="₦"
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
}
export const isValidEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
export const firebaseConfig = {
  apiKey: "AIzaSyCNIBwXu80OFCr-UXdbHUZeLfqBar8R4Ho",
  authDomain: "selpay-main.firebaseapp.com",
  projectId: "selpay-main",
  storageBucket: "selpay-main.appspot.com",
  messagingSenderId: "364010949380",
  appId: "1:364010949380:web:ed46b14fdb16669094de51",
  measurementId: "G-ELZLXS3G49",
};
export const vapidKey =
  "BFTsTfXxoNBGy1tDcxiFRvUe7fK89AkIAu5IOsNY6Y5J7jYOOYy9W53R5d0qhBHupEZV5BJbxG6hXRI9joWwYeU";
